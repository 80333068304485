.search-results{
	.bold{
		font-weight: bold;
	}
	.section-header{
		h2{
			top: 40%;
			margin:0;
		}
	}
	.result-container{
		h3{
			font-size: 1.5em;
			font-weight: normal;
			margin: 0;
		}
		ul{
			li{
				margin: 30px 0;
				a{
					text-decoration: none;
					h4{
						margin: 0;
						font-size: 1.125em;
						color: $puente-blue;
						font-weight: normal;
					}
					p{
						font-size: 0.875;
						margin: 0;
						color: $font-color-darker;
					}
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.search-results{
		.section-header{
			h2{
				top: 31%;
				left:18px;
				font-size: 1.4em;
			}
			img{
				width: 195%;
			    left: -58px;
			    position: relative;
			}
		}	
	}
}