.contact-container{
	.section-header{
		h2{
			// font-size: 2.25em;
			margin-top: 0;
			margin-bottom: 0;
			top: 48%;
			font-weight: 400;
		}
	}
	.title{
		font-size: 	1.5em;
		font-weight: bold;
		margin: 24px 0 24px 0;
	}
	.contact-form{
		input, select, textarea{
			font-family: Lato;
			border: 1px solid $dark-grey;
			width: 100%;
			display: block;
			text-indent: 10px;
			padding: 10px 0;
			margin: 0 0 12px 0;
			font-size: 0.875em;
			color: $font-color-darker;
			&.has-error{
				border: 1px solid red;
			}
		}
		select{
			-webkit-appearance: none;
 			-moz-appearance: none;
			background: transparent;
			padding: 9px 0;
			padding-right:1px;
			background: transparent url(../img/dropdown-btn.jpg) no-repeat right center;
			background-size: 40px 37px; /*TO ACCOUNT FOR @2X IMAGE FOR RETINA */
		}
		option{
			padding: 10px 0;
		}
		textarea{
			height: 66px;
		}
		.col{
			float: left;
			max-width: 366px;
			width: 47.5%;
			margin-right: 12px;
		}
		.same-row{
			max-width: 368px;
			width: 100%;
			input{
				max-width: 177px;
				width: 48%;
				&.right{
					float: right;
				}
				&:first-child{
					float: left;
				}

			}
		}
		.msg.hide{
			display: none;
		}
		
		.send-btn{
			background: $puente-blue;
			border: none;
			color: white;
			font-weight: bold;
			font-size: 1em;
			padding: 8px 22px;
			float: right;
			margin-right: 30px;
			cursor: pointer;
			margin-top: 10px;
			&:hover{
				background: $puente-blue-over;
			}
		}
	}
	.data{
		margin-top: 28px;
		.title,p{
			margin: 0;
		}
		p{
			margin-bottom: 0;
			color: $font-color-darker;
			font-weight: 300;
			margin: 0;
		}
		.phone-data{
			p{
				font-size: 2.25em;
			}
		}
		.schedules{
			margin-top: 34px;
			p{
				font-size: 1.5em;
				letter-spacing: -0.01em;
			}
		}
		.email-data{
			margin-top: 34px;
			a{
				text-decoration: none;
				color: $font-color-darker;
				font-size: 1.5em;
				font-weight: 300;
			}
		}
	}
	hr{
		border: none;
		width: 100%;
		height: 1px;
		background: transparent url(../img/contact-dotter-separator.jpg) 0 0 repeat-x;
	}
	.map{
		.title{
			margin-top: 0;
		}
	}
	p{
		color: $font-color-darker;
		font-weight: 300;
		font-size: 1.5em;
		margin: 20px 0;
	}
}
//tablet
@media (max-width: 1162px) {
	.contact-container{
		.data{
			.title,p{
			}
			p{
			}
			.phone-data{
				p{
					font-size: 3vw;
				}
			}
			.schedules{
				p{
					font-size: 1.9vw;
					letter-spacing: -0.01vw;
				}
			}
			.email-data{
				a{
					font-size: 1.9vw;
				}
			}
		}
	}
}

//mobile
@media (max-width: 767px) {

	.contact-container{
		top: 20%;
		.section-header{
			h2{
				left: 16px;
				top: 43%;
				font-size: 1.8em;
			}
			img{
				width: 195%;
			    left: -215px;
			    position: relative;
			}
		}
		.contact-form{
			input, select, textarea{
				width: 100%;
			}
			select{
				width: 100%;
			}
			textarea{
				width: 100%;
			}
			.col{
				width: 100%;
				max-width: none;
			}
			.same-row{
				width: 100%;
				max-width: none;
				input{
					max-width: 48%;
				}
			}
			.send-btn{
				margin-right: 0;
			}
		}

		.data{
			.title,p{
			}
			p{
			}
			.phone-data{
				p{
					font-size: 2.25em;
				}
			}
			.schedules{
				p{
					font-size: 1.2em;
					letter-spacing: -0.01vw;
				}
			}
			.email-data{
				a{
					font-size: 1.2em;
				}
			}
		}
	}

}