.services{
	.section-header{
		h2{
			top: 40%;
			margin:0;
		}
	}
	.puente-data{
		.images-container{
			display: flex;
			justify-content: space-between;
			img{

			}
		}
		.width-wrapper{
			width: 100%;
			max-width: 558px;
			p{
				font-size: 1.5em;
				color: $font-color-darker;
				font-weight: 300;
			}
		}
	}
	.puente-ge-data{
		h3{
			font-weight: bold;
			font-size: 1.4em;
			margin:0;
		}
		p{
			font-size: 1.115em;
			color: $font-color-darker;
			margin: 18px 0;
		}
		ul{
			li{
				h4{
					color: $font-color-darker;
					font-size: 1.125em;
					margin: 0;
				}
				p{
					margin: 0 0 18px 0;
					font-size: 0.875em;
				}
			}
		}
	}
	.soluciones {
		color: $font-color-darker;
		h3{
			font-weight: bold;
			font-size: 1.6em;
			margin:0;
		}
		p{
			font-size: 1.115em;
		}
		ul {
			list-style-type: square;
			padding-left: 20px;
			li {
				font-size: 1.115em;
				font-weight: 300;
			}
		}
	}
}


//tablet/destop
@media (max-width: 1162px) {
	.services{
		.puente-data{
			.images-container{
				overflow: hidden;
				img{
					width: 100%;
					height: 100%;
					&.hide-on-tablet{
						display: none;
					}
					&.margin{
						margin-right:5px;
					}
				}
			}
		}
	}
}

//mobile
@media (max-width: 767px) {
	.services{
		.section-header{
			h2{
				top: 35%;
				font-size: 1.3em;
				left: 20px;
			}
			img{
				width: 195%;
			    left: -58px;
			    position: relative;
			}
		}
		.puente-data{
			.images-container{
				img{
					width: 100%;
					height: 100%;
				}
			}
			.width-wrapper{
				width: auto;
				max-width: none;
			}
		}
	}
}