//site colors

//nav n footer background color
$ligth-grey: #f4f4f4;
//bullets background color
$dark-grey: #999999;
//footer background color
$grey: #ebebeb;
//puente montajes brand blue
$puente-blue: #005cb9;
//blue over
$puente-blue-over: #007fff;
//font color
$font-color: #333333;
//footer font color
$font-color-darker: #666666;
//
$mobile-margin: 20px;
//
$spacer-height: 22px;