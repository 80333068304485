///////////////////////
// resets Unsemantic
///////////////////////
.grid-container{
	max-width: 1137px;
}

/*****************************************************
//////////////////////////////////////////////////////
RESETS
//////////////////////////////////////////////////////
*****************************************************/
ul{
	padding: 0;
	margin: 0;
	list-style: none;
}
@mixin distribuidores-btn-styles(){
	margin-top: 21px;
	background: $puente-blue; 
	border: none;
	border-radius: 4px;
	padding: 7px 16px;
	color: white;
	font-family: Lato;
	font-weight: 700;
	cursor: pointer;
	&:hover{
		background: $puente-blue-over;
	}
}

img{
	border: none;
}

///////////////////////
//ClearFix
///////////////////////
.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.clearfix {display: inline-block;}
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

///////////////////////
//Global font
///////////////////////
body{
	font-family: Lato;
	font-size: 16px;
	padding: 0;
	margin: 0;
	height:100%;
	position:relative;
}

.bold{
	font-weight: bold;
}
.hidden{
	display: none;
}
///////////////////////
//Mise
///////////////////////
.top-mise{
	widows: 100%;
	height: 8px;
	background: $puente-blue;
}
///////////////////////
//Spacer
///////////////////////
.spacer{
	height: $spacer-height;
}

///////////////////////
//Menu mobile
///////////////////////
#menu-mobile-container{
	display: none;
	left: 100%;
}
.main-wrapper{
	position: relative;
	left: 0;

}
.main-wrapper, #menu-mobile-container{
 transition: left 0.5s;
}
///////////////////////
//pagination
///////////////////////
.pagination{
		.center{
			display: flex;
			justify-content: center;
		}
		button{
			float: left;
			border:none;
			background: transparent;
			padding: 0;
			margin: 0px;
			cursor: pointer;
			margin: -5px 5px 0 0;
			&.next{
				transform: rotate(180deg);
				margin: -8px 0 0 5px;
			}
		}
		ul{
			li{
				float: left;
				margin: 0 3px;
				a{
					text-decoration: none;
					color: $dark-grey;
					&.active{
						color:$font-color;
					}
				}
			}
		}
	}

/*****************************************************
//////////////////////////////////////////////////////
HEADER
//////////////////////////////////////////////////////
*****************************************************/
.main-header{
	.upper-nav{
		height: 132px;
		.left{
			margin-top: 28px;
			float: left;
			h1{
				margin: 0;
				padding: 0;
				a{
					*{
						width: 100%;
					}
					img{
						width: 100%;
    					max-width: 245px;
    					// min-height: 70px;
    					svg{
							width: 100%;
						}
					}
					svg{
						width: 100%;
					}
				}
			}
		}
		.right{
			position: relative;
			margin-top: 28px;
			float: right;
			*{
				float: left;
			}

			.distribuidores-btn{
				transition: opacity 0.3s ease 0.3s;
				opacity: 1;
				position: absolute;
				left: -168px;
				top: 0;
				width: auto;
				overflow: hidden;
				@include distribuidores-btn-styles();

				&.hidden{
					display: block;
					opacity: 0;
					width: 0px;
					margin: 0;
    				padding: 0;
				}
			}

			.btns-container{
				margin: 18px 20px 0 27px;
				.input-border{
					border: 2px solid $puente-blue;
					border-radius:18px;
					width: 183px;
					transition: width .3s;
					overflow: hidden;
					position: relative;
				}
				input{
					border: none;
				    background: transparent;
				    margin-top: 6px;
				    margin-left: 13px;
				    width: 140px;
				    color: $font-color-darker;
				    font-family: Lato;
				    position: absolute;
				    left: 0;
				    top: 0;
				}
				::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					color: $font-color-darker;
					font-family: Lato;
					font-style: italic;
					opacity: 0.7;
				}
				::-moz-placeholder { /* Firefox 19+ */
					color: $font-color-darker;
					font-family: Lato;
					font-style: italic;
					opacity: 0.7;
				}
				:-ms-input-placeholder { /* IE 10+ */
					color: $font-color-darker;
					font-family: Lato;
					font-style: italic;
				}
				:-moz-placeholder { /* Firefox 18- */
					color: $font-color-darker;
					font-family: Lato;
					font-style: italic;
					opacity: 0.7;
				}
				.search-btn{
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					cursor: pointer;
					margin: -2px;
					float: right;
					img{
						/*This will work for firefox*/
						@-moz-document url-prefix() {
							width: 29px;
							height: 32px;
						}
					}
				}
				.spacer{
					width: 1px;
					height: 32px;
					background: #e5e5e5;
					margin: 0 10px;
				}
				.btn:hover{
					opacity: .6;
				}
				&.hide{
					.input-border{
						width: 30px;
					}
					input{
						display: none;
					}
				}
			}
			.logo-puentes{
				img{
					max-width: 120px;
					width: 100%;
    				margin-top: 5px;
    				min-height: 58px
				}
			}
		}
	}
	.main-nav{
		background: $ligth-grey;
		width: 100%;
		overflow: hidden;
		height: 43px;
		border-radius: 1px;
		.menu-mobile-btn{
			display: none;
		}
		.distribuidores-btn{
			display: none;
		}
		ul{
			display: flex;
			justify-content: center;
			li{
				flex-grow: 1;
				text-align: center;
				display: block;
				float: left;
				margin: 11px 0 0 0;
				a{
					font-weight: 700;
					text-decoration: none;
					color: $font-color;
					&:hover{
						font-weight: 900;
					}
					&.is-active{
						font-weight: 900;
					}

				}
			}
		}
	}
}

/*****************************************************
//////////////////////////////////////////////////////
BANNER
//////////////////////////////////////////////////////
*****************************************************/
.banner{
	article{
		height: 159px;
		background: transparent url(../img/banner-big.jpg) 0 0 no-repeat;
		.banner-data{
			float: right;
			color: white;
			font-size: 1.5em;
			font-weight: 700;
			text-align: right;
			margin-right: 30px;
			span{
				font-weight: 300;
				font-size: 1.375em;
				line-height: 1.7em;
			}
		}
	}
}

/*****************************************************
//////////////////////////////////////////////////////
FOOTER
//////////////////////////////////////////////////////
*****************************************************/
.site-map-background{
	width: 100%;
	background: $ligth-grey;
	.site-map{
		.site-map-column{
			height: auto;

			.title-box{
				height: 38px;
				margin: 21px 0 5px 0;
				h4{
					a{
						text-decoration: none;
						color: $font-color;
					}
					margin: 0;
					color: $font-color;
				}
				img{
					display: none;
				}
				&.no-content{
					h4{
						margin: 0 0 10px 0;
					}
				}
			}
			ul{
				margin-bottom: 21px;

				li{
					a{
						text-decoration: none;
						font-size: 0.6875em;
						color: $font-color;
					}
				}
			}
			img{
				width: 100%;
			}
			p{
				text-decoration: none;
				font-size: 0.6875em;
				color: $font-color;
				line-height: 18px;
				margin: 0;
				a{
					text-decoration: none;
					color: $font-color;
				}
			}
			&.grid-15{
				width: 16.666666667%;
			}
			&.grid-40{
				width: 33.333333332%;
			}
		}
	}
}
.footer-background{
	width: 100%;
	overflow: hidden;
	height: 104px;
	background: $grey;
	.main-footer{
		.logo-ge-grey{
			margin-top: 16px;
			float: left;
		}
		.right{
			margin-top: 25px;
			float: right;
			p{
				float: left;
				font-size: 0.875em;
				color: $font-color-darker;
				margin-right: 20px;
				margin-top: 15px;

			}
			.logo-puente-grey{
				float: left;
			}
		}
	}
}

/*****************************************************
//////////////////////////////////////////////////////
SECTION-HEADER
//////////////////////////////////////////////////////
*****************************************************/
.section-header{
	position: relative;
	h2{
		position: absolute;
		top: 46%;
		margin: 0;
		left: 42px;
		color: white;
		font-weight: 400;
		font-size: 2.25em;
	}
	img{
		width: 100%;
	}
}

/*****************************************************
//////////////////////////////////////////////////////
LOGIN DISTRIBUIDORES
//////////////////////////////////////////////////////
*****************************************************/

body.show-login{
	overflow:hidden;
	.background-shadow{
		display: block;
	}
	#login-container{
		display: block;
	}
}

.background-shadow{
	display: none;
	width:100%;
	height:100%;
	background: black;
	opacity:0.7;
	position:fixed;
	top:0;
	left:0;
	z-index:9998;
}
#login-container{
	display: none;
	position: fixed;
	top: 20%;
	left: 50%;
	margin-left:-183.5px;
	width: 100%;
	max-width: 367px;
	background: white;
	z-index: 9999;
	.wrapper{
		width: 100%;
		max-width: 246px;
		background: white;
		margin: 0 auto 40px auto;
		h3{
			margin: 42px 0;
			color: $puente-blue;
			font-size: 1.5em;
			widows: 100%;
			text-align: center;
		}
		form{
			input{
				border: 1px solid $dark-grey;
				width: 100%;
				display: block;
				text-indent: 10px;
				padding: 10px 0;
				font-size: 0.875em;
				color: $font-color-darker;
				margin-bottom: 10px;
			}

			.forgot{
				width: 100%;
				text-align: right;
				font-size: 0.7em;
				margin: 0px;
				a{
					text-decoration: none;
					color: $dark-grey;
				}
			}
			.error{
				width: 100%;
				text-align: right;
				font-size: 0.7em;
				color: red;
				display: none;
			}
			button{
				background: $puente-blue;
				color: white;
				font-weight: bold;
				cursor: pointer;
				padding: 8px 22px;
				border: none;
				margin-top: 20px;
			}
		}
		hr{
			margin: 22px 0;
			border: none;
			width: 100%;
			height: 4px;
			background: transparent url(../img/login-dotted-border.jpg) 0 0 repeat-x;
		}
		.btn-no-distribuidor{
			width: 100%;
			text-align: center;
			display: block;
			background: white;
			color: $puente-blue;
			text-decoration: none;
			font-weight: bold;
			cursor: pointer;
			padding: 8px 0;
			border: 1px solid $puente-blue;
			margin-top: 25px;
		}
	}
	&.errors{
		.wrapper{
			form{
				input{
					border: 1px solid red;
				}
				.error{
					display: block;
				}
			}
		}
	}
}

/*****************************************************
//////////////////////////////////////////////////////
tablet/destop
//////////////////////////////////////////////////////
*****************************************************/
@media (max-width: 1162px) {
	//header
	.main-header{
		.upper-nav{
			height: 132px;
			.left{
				width: auto;
				h1{
				}
			}
			.right{
				width: auto;
				*{
				}
				.distribuidores-btn{
					&:hover{
					}
				}
				.btns-container{
					.search-btn{
					}
					.spacer{
					}
					.btn:hover{
					}
				}
			}
		}
		.main-nav{
			// height: 68px;
			ul{
				// height: 65px;
				li{
					// margin-top: 15px;
					a{
						// font-size: 0.8em;
						font-size: 1.4vw;
						display: inline-block;
						vertical-align: middle;
						&:hover{
						}
					}
				}
			}
		}
	}
	.banner{
		article{
			background-position: 44% 0;
			.banner-data{

				span{

				}
			}
		}
	}

	.site-map-background{
		.hide-on-tablet{
			display: none;
		}
		.tablet-grid-50{
			width: 50%!important;
		}
		.site-map{
			.site-map-column{
				min-height: 228px;
				height: auto;
				.title-box{
					h4{
					}
					img{

					}
					&.no-content{
						h4{
						}
					}
				}
				ul{
					margin-bottom: 21px;
					li{
						a{
						}
					}
				}
				img{
					margin-bottom: 15px;
				}
				p{
					a{
					}
				}
				&.grid-15{
				width: auto;
				}
				&.grid-40{
					width: auto;
				}
			}
		}
	}
	.footer-background{
		.main-footer{
			.logo-ge-grey{
			}
			.right{
				p{
				}
				.logo-puente-grey{
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.main-header{
		.main-nav{
			ul{
				li{
					a{
						font-size: 1.55vw;
					}
				}
			}
		}
	}
}

/*****************************************************
//////////////////////////////////////////////////////
Mobile
//////////////////////////////////////////////////////
*****************************************************/
@media (max-width: 767px) {

	.grid-container{
		padding: 0;
		.grid-5, .mobile-grid-5, .grid-10, .mobile-grid-10, .grid-15, .mobile-grid-15, .grid-20, .mobile-grid-20, .grid-25, .mobile-grid-25, .grid-30, .mobile-grid-30, .grid-35, .mobile-grid-35, .grid-40, .mobile-grid-40, .grid-45, .mobile-grid-45, .grid-50, .mobile-grid-50, .grid-55, .mobile-grid-55, .grid-60, .mobile-grid-60, .grid-65, .mobile-grid-65, .grid-70, .mobile-grid-70, .grid-75, .mobile-grid-75, .grid-80, .mobile-grid-80, .grid-85, .mobile-grid-85, .grid-90, .mobile-grid-90, .grid-95, .mobile-grid-95, .grid-100, .mobile-grid-100, .grid-33, .mobile-grid-33, .grid-66, .mobile-grid-66 {
			padding: 0;
		}
	}

	#menu-mobile-container{
		display: block;
		background: $puente-blue;
		position: absolute;
		width: 100%;
		left: 100%;
		z-index: 999999;
		top: 0;
		height: 100%;
		overflow: hidden;
		ul{
			li{
				margin: 26px 20px;
				a{
					text-decoration: none;
					color: white;
					font-weight: bold;
					font-size: 1.1em;
				}
				&.search{
					.input-border{
						border: 1px solid white;
					    border-radius: 18px;
					    width: 215px;
						input{
							background: transparent;
							border: none;
							padding: 10px 0 10px 10px;
							color: white;
							font-family: Lato;
						}

						::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							color: white;
							font-family: Lato;
							font-style: italic;
						}
						::-moz-placeholder { /* Firefox 19+ */
							color: white;
							font-family: Lato;
							font-style: italic;
						}
						:-ms-input-placeholder { /* IE 10+ */
							color: white;
							font-family: Lato;
							font-style: italic;
						}
						:-moz-placeholder { /* Firefox 18- */
							color: white;
							font-family: Lato;
							font-style: italic;
						}
						button{
							background: transparent;
							border: none;
							margin-top: 6px;
							padding: 0;
							float: right;
							cursor: pointer;

							img{
								width: 20px;
								float: right;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
	body{
		overflow-x: hidden;
	}

	body.menu-open{
		overflow: hidden;
		#menu-mobile-container{
			left: 25%;
		}
		.main-wrapper{
			left:-75%;
		}
	}

	.mobile-wrapper{
		margin-left: $mobile-margin;
		margin-right: $mobile-margin;
	}
	//header
	.main-header{
		.upper-nav{
			height: 73px;
			.left{
				margin-top: 12px;
				width: 63%;
				h1{
					margin-left: $mobile-margin;
					img{
						width: 100%;
						max-width: 300px;
						height: auto;
					}
				}
			}
			.right{
				width: 30%;
				margin-top: 5px;
				.logo-puentes{
					margin-right: 15px;
					img{
						width: 100%;
						height: auto;
					}
				}
				*{
					float: right;
				}
				.distribuidores-btn{
					display: none;
					&:hover{
					}
				}
				.btns-container{
					display: none;
					.search-btn{
					}
					.spacer{
					}
					.btn:hover{
					}
				}
			}
		}
		.main-nav{
			height: 43px;
			.menu-mobile-btn{
				display: block;
				position: relative;
				float: right;
				margin: 5px $mobile-margin 0 0;
				width: 32px;
				height: 32px;
				border: 1px solid $puente-blue;
				&:before {
					content: "";
					position: absolute;
					left: 5px;
					top: 8px;
					width: 22px;
					height: 3px;
					background: $puente-blue;
					box-shadow: 0 14px 0 0 $puente-blue, 0 7px 0 0 $puente-blue;
				}
			}
			.distribuidores-btn{
				@include distribuidores-btn-styles();
				display: block;
				margin: 7px 0 0 $mobile-margin;
				font-size: 0.9em;
			}
			ul{
				height: 65px;
				li{
					display:none;
					margin-top: 15px;
					a{
						// font-size: 0.8em;
						display: inline-block;
						vertical-align: middle;
						&:hover{
						}
					}
				}
			}
		}
	}

	.banner{
		article{
			background: transparent url(../img/banner-mobile.jpg) 0 0 no-repeat;
			height: 130px;
			background-size: cover;
			.banner-data{
				margin: 27px $mobile-margin 0 0;
				font-size: 1.2em;

				span{

				}
			}
		}
	}

	.site-map-background{
		background: $ligth-grey;
		.site-map{
			.site-map-column{
					height: auto;
				.title-box{
					margin: 0;
					&.border-bottom{
						border-bottom: 1px solid $grey;
					}
					h4{
						display: block;
						float: left;
						margin: 10px 0 0 $mobile-margin;
						font-size: 1.1em;
						br{
							display: none;
						}
						&:after{

						}
					}
					img{
						display: block;
						float: right;
						width: 18px;
						margin: 10px $mobile-margin 0 0;
					}
				}
				ul{
					display: none;
					li{
						a{
						}
					}
				}
				img{
				}
				p{
					margin: 0 $mobile-margin 15px $mobile-margin;
					font-size: 1em;
					br{
						// display: none;
					}
					a{
					}
				}
				&.mobile-grid-100{
					width: 100%!important;
				}
			}
		}
	}
	.footer-background{
		background: $grey;
		.main-footer{

			.right{
				float: left;
				width: 100%;
				p{
					text-align: left;
					margin-left: $mobile-margin;
				}
				a{
					float: right;
					display: block;
					margin:10px $mobile-margin 0 0;
					img{
						width: 33px;
					}
				}
			}
		}
	}
	#login-container{
		top: 8%;
	    left: 0;
	    margin-left: 0;
	}
}