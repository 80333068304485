.news-container{
	.section-header{
		h2{
			br{
				display: none;
			}
			top: 40%;
		}
	}
	.news{

		h3{
			margin-top: 0;
			font-size: 1.5em;
		}
		p{
			color: $font-color-darker;
			font-size: 0.875em;
			line-height: 180%;
		}
		img{
			width: 100%;
		}
		hr{
			border: none;
			width: 100%;
			height: 1px;
			background: transparent url(../img/news-dotted-border.jpg) 0 0 repeat-x;
		}
	}
}



@media (max-width: 767px) {
	.news-container{
		.section-header{
			h2{
			top: 28%;
			font-size: 1.4em;
			left: 20px;
				br{
					display: block;
				}
			}
			img{
				position: relative;
				width: 150%;
				left: -50%;
			}
		}
	}
}