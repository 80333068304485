.lighting {
    .section-header { 
		h2 {
			width: 70%;
			top: 50%;
			margin:0;
		}

		.img-desktop {
			display: block;
		}

		.img-mobile {
			display: none;
		}

		#sub {
			top: 67%;
			font-size: 1.5em;
		}

		@media (max-width: 1024px) {
			h2 {
				font-size: 1.4em;
			}

			#sub {
				font-size: 1.2em;
			}
		}

		@media (max-width: 767px) {
			.img-desktop {
				display: none;
			}

			.img-mobile {
				display: block;
			}

			h2 {
				width: 50%;
				top: 31%;
				left:18px;
				font-size: 1.4em;
			}

			#sub {
				font-size: 1em;
			}
		}

		@media (max-width: 440px) {
			h2 {
				width: 50%;
				top: 31%;
				left:18px;
				font-size: 1em;
			}

			#sub {
				font-size: 1em;
			}
		}
	}
	
	.intro {	
		.row {
			display: flex;
			align-items: center;

			@media (max-width: 1162px) {
				display: block;
			}
		}

		.lighting-data {	
			p {
				font-size: 1.5em;
				color: $font-color-darker;
				font-weight: 300;
			}
		}
		
		img {
			width: 100%;
			height: auto;
		}

		@media(max-width: 767px) {
			margin: 0 20px;
		}
	}

	.product-categories-container{

		@media(max-width: 767px){
			margin: 0 20px;
		}

		.lamp-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@media(min-width: 767px){
				justify-content: space-between;
			}

			.lamp {
				display: inline;
				padding-bottom: 0.5em;
	
				.description{
					color: $font-color-darker;
					margin-left: 0.5em;
					margin-top: 0.5em;
					font-weight: 200;
					
					.download {
						display: flex;
						justify-content: space-between;
						text-decoration: none;
						color: $font-color-darker;
					
						h3 {
							margin-top: 0em;				
							margin-bottom: 0em;
							font-weight: 400;
						
						}
	
						&:hover{
							opacity: 0.7;
						}
					}
					
					p {
						margin-top: 0.4em;
						font-size: 1em;
					}				
				}
	
				.partner {
					background-color: #4f71b1;
					float: right;
					
					.container {
						padding: 24px 33px;					
						
						h2 {
							color: #ffffff;
							font-weight: 400;
							margin: 0px;
						}
				
						.general-text {
							display: flex;
							align-items: center;
							
							@media (max-width:768px){
								margin-top: 1em;								
							}
	
							@media (max-width: 320px) {
								display: block;
							}

							img {
								height: auto;

								@media (max-width: 320px) {
									width: auto;
								}
							}
				
							p {
								color: #ffffff;
								font-weight: 200;
								margin-left: 1.5em;
								font-size: 0.9em;

								@media (max-width: 320px) {
									margin-left: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

#blue-box {
	padding-right: 0px;

	@media (max-width: 1156px) {
		margin: 0px;
	}

	h2 {
		margin-right: 0;
		@media (min-width: 767px) {
			margin-right: 6rem;
		}
	}
}