.product-container{
	.section-header{
		display: none;
	}
	.breadcrumb{
		position: relative;
		img{
			width: 100%;
		}
		p{
			top: 50%;
			left: 36px;
			margin: -10px 0 0 0;
			position: absolute;
			color: white;
			font-size: 0.9em;
			a{
				text-decoration: none;
				color: white;
			}
			span{
				
			}
		}		
	}
	.product-nav{
		margin-top: -10px;
		margin-bottom: 5px;
		height: 25px;
		.btn{
			border: none;
			font-size: 0.8em;
			background: transparent;
			color: $puente-blue;
			cursor: pointer;
			height: 30px;
			overflow: visible;
			&.prev{
				margin-left: -6px;
				float: left;
				.icon-arrow{
					transform: rotate(180deg);
				}
			}
			&.next{
				margin-right: -6px;
				float: right;
			}
			.icon-arrow{
				margin-bottom: -8px;
				display: inline-block;
				width: 24px;
				height: 24px;
				background: transparent url(../img/icon-arrow-active-btn.jpg) 0 0 no-repeat;
			}
		
			&.disabled{
				color: $dark-grey;
				cursor: default;
				display: none;
				.icon-arrow{
					background: transparent url(../img/icon-arrow-inactive-btn.jpg) 0 0 no-repeat;
				}
			}
		}
	}
	.product-data-container{
		.title{
			margin-top: 35px;
			max-width: 482px;
			color: $dark-grey;
			h1{
				font-size: 2.3em;
				font-weight: normal;
				margin: 0 0 25px 0;
				color: $dark-grey;
			}
			hr{
				border: none;
				width: 100%;
				height: 1px;
				background: transparent url(../img/dotted-border.jpg) 0 0 repeat-x;
			}
			p{
				margin: 20px 0 0 0;
				font-size: 1.5em;
				font-weight: 300;
				color: $dark-grey;
			}

		}
		.product-image-backgroud{
			width: 100%;
		}
		.features{
			margin-top: 20px;
			h2{
				color: $puente-blue;
				font-size: 2em;
				position: relative;
				margin: 0 0 0 20px;
				&:before{
					content: "";
					width: 11px;
					height: 48px;
					background: $puente-blue;
					position: absolute;
					top: -6px;
					left: -20px;
				}
			}
		}
		.product-gallery{
			margin-top: 20px;
			.images-container{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				background: $ligth-grey;
				padding: 15px;
				max-width: 502px;
				float: right;
				img{
					cursor: pointer;
					width: 32.3333%;
					height: 100%;
					margin-bottom: 4px;
					&:hover{
						opacity: .7;
					}
					&.noImage{
						cursor: default;
					}
				}
			}
		}
		.more-info{
			max-width: 548.500px;
			width: 100%;
			padding: 0;
			float: right;
			&.tablet-mobile{
				display: none;
			}
			&.desktop{
				display: block;
			}
			.downloads{
				margin-top: 30px;
				margin-left: 17px;
				float: left;
				h3{
					color: $puente-blue;
					font-weight: bold;
					font-size: 1.4em;
					margin: 0;
				}
				ul{
					margin: 15px 0 0 12px;
					li{
						position: relative;
						max-width: 260px;
						&:before{
							content:"";
							left: -11px;
							top: 9px;
							width: 4px;
							height: 4px;
							border-radius: 3px;
							background: $dark-grey;
							position: absolute;

						}
						a{
							color: $dark-grey;
							text-decoration: none;
							font-weight: bold;
							&:hover{
								opacity: .8;
							}
						}
					}
				}
			}
			.contact{
				margin-top: 30px;
				float: right;
				button{
					background: $puente-blue url(../img/icon-email.png) 176px 13px no-repeat;
					border: none;
					color: white;
					font-size: 1.0em;
					padding: 20px 70px 20px 21px;
					cursor: pointer;
					text-decoration: none;
					color: white;
					a{

						text-decoration: none;
						color: white;
					}
					&:hover{
						background-color: $puente-blue-over;
					}
				}
			}
		}
	}
}

//tablet/destop
@media (max-width: 1162px) {
	.product-container{
		.breadcrumb{
			p{
				font-size: 0.8em;
			}
		}
		.product-data-container{


			.more-info{
				width: 100%;
				margin-left: 0;
				max-width: none;
				padding: 0 10px;
				&.tablet-mobile{
					display: block;
				}
				&.desktop{
					display: none;
				}
				.downloads{
					float: left;
				}
				.contact{
					float: right;
					button{
					}
				}
			}
		}
	}
}

//mobile
@media (max-width: 767px) {
	.product-container{
		.section-header{
			display: block;
			.img-mobile{
				display: block;
			}
			h2{
				top: 31%;
				left:18px;
				font-size: 1.4em;
				 width: 145px;
				br{
					display: block;
				}
			}
			&.control{
				h2{
					font-size: 1.3em;
				}	
			}
		}
		.breadcrumb{
			display: none;
		}

		.product-nav{
			margin-left: $mobile-margin;
			margin-right: $mobile-margin;
		}
		.product-data-container{
			width: 100%;
			
			.title{
				
			}

			.product-image-backgroud{
			}
			
			
			.product-gallery{
				.images-container{
					img{
						width: 49.4%;
						height: 100%;
					}
				}
			}
			.more-info{
				max-width: none;
				width: 100%;
				float: none;
				&.tablet-mobile{
					display: block;
				}
				&.desktop{
					display: none;
				}
				.downloads{
					margin-left: 0px;
					float: none;
				}
				.contact{
					float: none;
					button{
						margin: 0 auto;
						display: block;
					}
				}
			}
		}
	}
}