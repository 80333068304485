.product-categories-container{
	.section-header{
		.img-desktop{
			display: block;
		}
		.img-mobile{
			display: none;
		}
		br{
			display: none;
		}
		h2{
    		font-weight: normal;
		}
	}

	.product-categories-container{

		.gutter{
			width: 10px;
			float: left;
			height: 50px;
		}
		margin-left: -10px;
		.product-class{
			overflow: hidden;
			width: 100%;
			max-width: 177px;
			float: left;
			margin-left: 10px;
			//height: 425px;
			.title-box{
				// height: 260px;
				   height: 244px;
				h3{
					font-weight: 300;
					font-size: 1.1em;
					margin: 5px 0;
				}
				img{
					width: 100%;
				}
			}
			ul{
				li{
					a{
						color: $puente-blue;
						text-decoration: none;
						font-size: 0.8em;
						&:hover{
							color: $puente-blue-over;
						}
					}
					margin-bottom: 10px;
				}
			}

		}
	}
}

//tablet/destop
@media (max-width: 1162px) {

}


//mobile
@media (max-width: 767px) {
	.product-categories-container{
		.section-header{
			.img-desktop{
				display: none;
			}
			.img-mobile{
				display: block;
			}
			h2{
				top: 31%;
				left:18px;
				font-size: 1.4em;
				br{
					display: block;
				}
			}
			&.control{
				h2{
					font-size: 1.3em;
				}	
			}
		}
		.product-categories-container{
			margin: 0 $mobile-margin;
			.product-class{
				height: auto;
				width: 100%;
				max-width: none;
				float: left;
				margin: 0 0 22px 0;
				.title-box{
					height: auto;
					h3{
						font-weight: 400;
						font-size: 1.5em;
						margin: 11px 0 15px 0;
						width: 90%;	
						color: $font-color-darker;
					}
					img{
						width: 100%;
					}
				}
				ul{
					li{
						width: 90%;	
						a{
							color: $puente-blue;
							text-decoration: none;
							font-size: 1em;
							font-weight: bold;
							&:hover{
								color: $puente-blue-over;
							}
						}
						margin-bottom: 15px;
					}
				}

			}
		}
	}
}