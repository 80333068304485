
//slider
.swiper-container {
    width: 100%;
    height: 367px;
	.swiper-button-prev, .swiper-button-next{
		display: none;
	}
	.swiper-pagination.swiper-pagination-bullets{
		span{
			width: 29px;
			height: 4px;
			border-radius: 0;
			background: white;
			opacity: 0.6;
			&.swiper-pagination-bullet-active{
				opacity: 1;
				background: $puente-blue;

			}
		}
	}
}
.slider{
	.slider-item{
		height: 367px;
		background-position: 0;
		background-repeat: no-repeat;
		.item-data{
			color: white;
			float: left;
			width: 80%;
			max-width: 550px;
			margin: 50px 40px 0 40px;
			h2{
				margin: 0 0 30px 0;
				font-size: 3em;
			}
			p{
				font-size: 1.5em;
				font-weight: 400;
				margin: 0;
				position: relative;
				&:before{
					content: "";
				    width: 10px;
				    height: 1px;
				    background: white;
				    position: absolute;
				    top: -10px;
				}
			}
		}
		.item-image{
			margin: 0 59px 0 0;
			float: right;
			display: block;
			position: relative;
			z-index: 9999;
		}
		.blue-shape{
			position: absolute;
			right: 10px;
			top: 0;
			z-index: 10;
		}
	}
}

//bullets
.bullets-container{
	padding: 0 10px;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	.bullet{

		max-width: 365px;
		flex: 1;
		.bullet-link{
			text-decoration: none;
			display: block;
		}
		img{
			border: none;
			margin-bottom: -4px;
			width: 100%;
		}
		&.middle{
			margin: 0 10px;
		}
		.bullet-data-container{
			padding: 0px 20px 10px 20px;
			background: $dark-grey;
			overflow: hidden;
			color: white;
			h3{
				margin: 5px 0 0 0;
				font-size: 1.75em;
				font-weight: 400;
			}
			p{
				font-family: Raleway;
				font-weight: 400;
				line-height: 1.3em;
				font-size: 0.875em;
			}
		}
	}
}



//tablet/destop
@media (max-width: 1162px) {
	.slider{
		.slider-item{
			.item-data{
				max-width: none;
				width: 40%;
				h2{
				}
				p{
					&:before{
					}
				}
			}
			.item-image{
				width: 250px;
				margin-top: 68px;
			}
		}
	}
	.bullets-container{
		padding: 0 2.5px 0 7px;
		.bullet{
			max-width: none;

			padding: 0 5px;
			&.middle{
				margin: 0;
			}
			&.left{
				margin-left: -2.5px;
			}
			&.right{
				margin-right: 2px;
			}

			.bullet-data-container{
				h3{

				}
				p{
				}
			}
		}
	}

}
//mobile
@media (max-width: 767px) {
	.swiper-container {
		height: 390px;
	}
	.slider{
		.slider-item{
			height: 390px;
			// background: right 0 no-repeat !important;
			background-position: center;
			background-color: rgba(0,0,0,0.5);
			background-blend-mode: multiply;
			-webkit-background-blend-mode: multiply;
			background-size: cover !important;
			text-align: center;
			.item-data{
				width: 100%;
				text-align: center;
				margin: 20px 0 0 0;
				h2{
					width: auto;
					font-size: 1.7em;
					margin-bottom: 32px;
					font-weight: normal;
					margin: 0 20px 32px 20px;
				}
				p{
					width: auto;
					margin: 0 20px 32px 20px;
					text-align: center;
					font-size: 0.9em;
					&:before{
						left: 50%;
						top: -17px;
					}
				}
			}
			.item-image{
				width: 223px;
				position:relative;
				display: inline;
				float: none;
				top: -40px;
				margin: 10px -40px 0 0;
			}
		}
	}
	.bullets-container{
		padding: 0;
		display: block;
		.bullet{
			max-width: none;

			padding: 0;
			&.middle{
				margin: 0;
			}
			&.left{
				margin-left: 0;
			}
			&.right{
				margin-right: 0;
			}
			&.mobile-margin-bottom{
				margin-bottom: $spacer-height;
			}

			.bullet-data-container{
				h3{

				}
				p{
				}
			}
		}
	}
}